import Tabbar from "@/components/tabbar";
import Dialog from '@/components/dialog';
import { removeCookie } from "@/utils/auth";
export default {
  data() {
    return {
      showCenter: false,
      btnLogin: false,
      showDownBtn: false
    };
  },
  created() {
    if (process.env.VUE_APP_TYPE != 'app') {
      this.showDownBtn = true;
    }
  },
  methods: {
    openOutPop() {
      this.showCenter = true;
    },
    goOut() {
      this.$store.dispatch("goOut");
      removeCookie('token');
      this.$router.replace("/");
    },
    openServer() {
      window.open(this.userInfo.serviceUrl);
    },
    downApp() {
      window.open(this.userInfo.appUrl);
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  components: {
    Tabbar,
    Dialog
  }
};